<template>
  <v-form :readonly="readonly">
    <v-container>
      <v-row>
        <v-col cols="12" md="8">
          <template v-if="approvalLevelId">
            <user-select
              :users="users"
              :value="value.userId"
              @handleUserSelect="handleUserSelect($event)"
              label="User"
              type="User"
              v-if="value.userId"
              v-model.trim="$v.form.userId.$model"
            ></user-select>

            <user-select
              :users="users"
              :value="value.userEmail"
              @handleUserSelect="handleUserSelect($event)"
              label="User"
              type="User"
              v-else
              v-model.trim="$v.form.userEmail.$model"
            ></user-select>
          </template>

          <v-select
            v-if="userId"
            :items="filteredApprovalLevels"
            v-model="$v.form.approvalLevelId.$model"
            label="Approval Level"
            item-text="name"
            item-value="id"
            @change="handleApprovalLevelSelected($event)"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-checkbox v-model="$v.form.isPrimary.$model" label="Primary"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-autocomplete
            multiple
            :items="schools"
            v-model="$v.form.locationIds.$model"
            label="Schools"
            item-text="name"
            item-value="id"
            @change="handleLocationChange"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                (+{{ $v.form.locationIds.$model.length - 2 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="4" class="checkbox">
          <v-checkbox v-model="selectAllLocations" label="All" @change="handleAllLocations"></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-autocomplete
            multiple
            :items="tripTypes.filter((trip) => availableTripTypes.includes(trip.id))"
            v-model="$v.form.tripTypeIds.$model"
            label="Trip Types"
            item-text="name"
            item-value="id"
            @change="handleTripTypeChange"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2" :disabled="availableTripTypes && !availableTripTypes.includes(item.id)">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                (+{{ $v.form.tripTypeIds.$model.length - 2 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="4" class="checkbox">
          <v-checkbox v-model="selectAllTypes" label="All" @change="handleAllTypes"></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <v-row dense v-if="!readonly">
      <v-col cols="12" md="3">
        <v-btn color="error" text @click="deleteApproverRole()" v-if="!readonly && form.userId != 0" :loading="saving">
          delete
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="3">
        <v-btn class="float-right" color="success" @click="save()" v-if="!readonly" :loading="saving"> Save </v-btn>
        <v-btn class="float-right mr-2" color="error" @click="close()" v-if="isDialog"> Cancel </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { SAVE_APPROVER, DELETE_APPROVER } from '@/store/modules/Approver/actions';
import UserSelect from '@/components/UserSelect.vue';

const newApprover = {
  userId: 0,
  userEmail: '',
  approvalLevelId: 0,
  locationIds: [],
  tripTypeIds: [],
  isPrimary: true,
};

export default {
  name: 'ApproverCreator',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: { UserSelect },
  props: {
    value: Object,
    approvalLevels: Array,
    users: Array,
    filteredTripTypes: Array,
    approvalLevelId: Number,
    userId: Number,
    approverIndex: Number,
    refresh: Function,
    removeApprover: Function,
    close: Function,
    dialog: Boolean,
    readonly: Boolean,
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: newApprover,
      availableTripTypes: [],
      selectAllLocations: false,
      selectAllTypes: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters('location', ['schools']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    ...mapGetters('user', ['usersById']),
    filteredApprovalLevels() {
      if (!this.approvalLevels.length) return [];

      return this.approvalLevels.filter(
        (item) => !item.criteria.some((criterion) => criterion.label === 'Funding Source')
      );
    },
  },
  validations: {
    form: {
      userId: {},
      userEmail: {},
      approvalLevelId: { required },
      locationIds: {},
      tripTypeIds: {},
      isPrimary: { required },
    },
  },
  methods: {
    ...mapActions('approver', [SAVE_APPROVER, DELETE_APPROVER]),
    async save() {
      if (
        !(this.$v.form.userId.$model || this.$v.form.userEmail.$model || this.userId) ||
        !(this.$v.form.approvalLevelId.$model || this.approvalLevelId) ||
        !this.$v.form.locationIds.$model.length ||
        !this.$v.form.tripTypeIds.$model.length
      ) {
        this.$myalert.error('Please complete all fields');

        return;
      }

      try {
        this.saving = true;

        if (this.userId) {
          await this.saveApprover({
            ...this.$v.form.$model,
            userId: this.userId,
            userEmail: this.usersById[this.userId].email,
          });
        } else if (this.approvalLevelId) {
          await this.saveApprover({ ...this.$v.form.$model, approvalLevelId: this.approvalLevelId });
        }

        this.$myalert.success('Approver saved');

        if (this.refresh) this.refresh();
        if (this.close) this.handleClose();
      } catch (error) {
        this.$myalert.error(error.message);
      }

      this.saving = false;
    },
    async deleteApproverRole() {
      let ok = true;

      if (!this.value.uuid) return;

      ok = await this.$myconfirm('Are you sure you want to delete this Approver Role?');

      if (!ok) return;

      this.saving = true;

      try {
        const r = await this.deleteApprover(this.value.uuid);

        if (!r.done) return;

        this.$myalert.success('Approver deleted');

        if (this.refresh) this.refresh();
        if (this.close) this.handleClose();
      } catch (e) {
        this.$myalert.error(e.message);
      }

      this.saving = false;
    },
    handleUserSelect(user) {
      if (typeof user === 'string') {
        this.$v.form.userEmail.$model = user;
      } else {
        this.$v.form.userId.$model = user;
        this.$v.form.userEmail.$model = this.users.find((e) => e.id == user).email;
      }

      this.$v.form.$touch();
    },
    handleLocationChange(e) {
      if (this.$v.form.locationIds.$model.length == this.schools.length) {
        this.selectAllLocations = true;
      } else {
        this.selectAllLocations = false;
      }

      this.$v.form.locationIds.$touch();
    },
    handleTripTypeChange(e) {
      if (this.$v.form.tripTypeIds.$model.length == this.availableTripTypes.length) {
        this.selectAllTypes = true;
      } else {
        this.selectAllTypes = false;
      }

      this.$v.form.tripTypeIds.$touch();
    },
    handleAllLocations() {
      if (this.$v.form.locationIds.$model.length == this.schools.length) {
        this.$v.form.locationIds.$model = [];
      } else {
        this.$v.form.locationIds.$model = this.schools.map((e) => e.id);
      }
    },
    handleAllTypes() {
      if (this.$v.form.tripTypeIds.$model.length == this.availableTripTypes.length) {
        this.$v.form.tripTypeIds.$model = [];
      } else {
        this.$v.form.tripTypeIds.$model = this.availableTripTypes.map((e) => e);
      }
    },
    checkTripTypes() {
      this.availableTripTypes = this.filteredTripTypes;

      if (this.availableTripTypes && this.availableTripTypes.length) return;

      if (this.approvalLevelId) {
        this.availableTripTypes = this.filteredApprovalLevels.find((e) => e.id == this.approvalLevelId).tripTypeIds;
      } else {
        this.availableTripTypes = this.tripTypes.map((e) => e.id);
      }
    },
    handleApprovalLevelSelected(e) {
      this.availableTripTypes = this.approvalLevelsById[e].tripTypes;
    },
    handleClose() {
      this.selectAllLocations = false;
      this.selectAllTypes = false;
      this.close();
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.form = { ...newApprover, ...value };

        if (this.form.locationIds && this.schools && this.form.locationIds.length == this.schools.length) {
          this.selectAllLocations = true;
        }

        if (
          this.form.tripTypeIds &&
          this.filteredTripTypes &&
          this.form.tripTypeIds.length == this.filteredTripTypes.length
        ) {
          this.selectAllTypes = true;
        } else if (!this.filteredTripTypes && this.form.tripTypeIds.length == this.tripTypes.length) {
          this.selectAllTypes = true;
        }

        this.checkTripTypes();
      },
    },
    dialog(value) {
      if (!value) this.handleClose();
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
