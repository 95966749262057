var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: _vm.readonly } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _vm.approvalLevelId
                    ? [
                        _vm.value.userId
                          ? _c("user-select", {
                              attrs: {
                                users: _vm.users,
                                value: _vm.value.userId,
                                label: "User",
                                type: "User",
                              },
                              on: {
                                handleUserSelect: function ($event) {
                                  return _vm.handleUserSelect($event)
                                },
                              },
                              model: {
                                value: _vm.$v.form.userId.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.form.userId,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.form.userId.$model",
                              },
                            })
                          : _c("user-select", {
                              attrs: {
                                users: _vm.users,
                                value: _vm.value.userEmail,
                                label: "User",
                                type: "User",
                              },
                              on: {
                                handleUserSelect: function ($event) {
                                  return _vm.handleUserSelect($event)
                                },
                              },
                              model: {
                                value: _vm.$v.form.userEmail.$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.form.userEmail,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.form.userEmail.$model",
                              },
                            }),
                      ]
                    : _vm._e(),
                  _vm.userId
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.filteredApprovalLevels,
                          label: "Approval Level",
                          "item-text": "name",
                          "item-value": "id",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleApprovalLevelSelected($event)
                          },
                        },
                        model: {
                          value: _vm.$v.form.approvalLevelId.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.form.approvalLevelId, "$model", $$v)
                          },
                          expression: "$v.form.approvalLevelId.$model",
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "Primary" },
                    model: {
                      value: _vm.$v.form.isPrimary.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.isPrimary, "$model", $$v)
                      },
                      expression: "$v.form.isPrimary.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      multiple: "",
                      items: _vm.schools,
                      label: "Schools",
                      "item-text": "name",
                      "item-value": "id",
                    },
                    on: { change: _vm.handleLocationChange },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ item, index }) {
                          return [
                            index < 2
                              ? _c("v-chip", [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ])
                              : _vm._e(),
                            index === 2
                              ? _c(
                                  "span",
                                  { staticClass: "grey--text text-caption" },
                                  [
                                    _vm._v(
                                      " (+" +
                                        _vm._s(
                                          _vm.$v.form.locationIds.$model
                                            .length - 2
                                        ) +
                                        " others) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.$v.form.locationIds.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.locationIds, "$model", $$v)
                      },
                      expression: "$v.form.locationIds.$model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "checkbox", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "All" },
                    on: { change: _vm.handleAllLocations },
                    model: {
                      value: _vm.selectAllLocations,
                      callback: function ($$v) {
                        _vm.selectAllLocations = $$v
                      },
                      expression: "selectAllLocations",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      multiple: "",
                      items: _vm.tripTypes.filter((trip) =>
                        _vm.availableTripTypes.includes(trip.id)
                      ),
                      label: "Trip Types",
                      "item-text": "name",
                      "item-value": "id",
                    },
                    on: { change: _vm.handleTripTypeChange },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ item, index }) {
                          return [
                            index < 2
                              ? _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.availableTripTypes &&
                                        !_vm.availableTripTypes.includes(
                                          item.id
                                        ),
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                )
                              : _vm._e(),
                            index === 2
                              ? _c(
                                  "span",
                                  { staticClass: "grey--text text-caption" },
                                  [
                                    _vm._v(
                                      " (+" +
                                        _vm._s(
                                          _vm.$v.form.tripTypeIds.$model
                                            .length - 2
                                        ) +
                                        " others) "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.$v.form.tripTypeIds.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.tripTypeIds, "$model", $$v)
                      },
                      expression: "$v.form.tripTypeIds.$model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "checkbox", attrs: { cols: "12", md: "4" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "All" },
                    on: { change: _vm.handleAllTypes },
                    model: {
                      value: _vm.selectAllTypes,
                      callback: function ($$v) {
                        _vm.selectAllTypes = $$v
                      },
                      expression: "selectAllTypes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.readonly
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  !_vm.readonly && _vm.form.userId != 0
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "error",
                            text: "",
                            loading: _vm.saving,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteApproverRole()
                            },
                          },
                        },
                        [_vm._v(" delete ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  !_vm.readonly
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "success", loading: _vm.saving },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e(),
                  _vm.isDialog
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }